import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { selectOrderTotal } from 'selectors/order';
import { setLoyaltySpend } from 'actions/loyalty';
import { BtnContainer, CouponContainer, Input, InputContainer } from 'components/PromotionCoupon/styles';
import { User } from 'hooks/useLoyalty';
import FormattedPrice from 'components/FormattedPrice';
import { ErrorMessage, Label } from 'assets/styles/sharedStyles';
import ActionButton from 'components/ActionButton';

const Title = styled.div`
  font-weight: bold;
  margin-block-end: 0.5rem;
`;

const StyledInput = styled(Input)`
  background-color: white;
  color: #5e6d70;
`;

interface ComoProps {
  user: User;
  value?: string;
  onChange: Function;
}

const Como: React.FC<ComoProps> = ({ user, value, onChange }) => {
  const dispatch = useDispatch();
  const orderTotal = useSelector(selectOrderTotal);

  const [error, setError] = useState<string>('');
  const availableCredits = user?.credit || 0;

  const [pointsToSpend, setPointsToSpend] = useState(parseFloat(value));
  const [applied, setApplied] = useState(false);

  const handleClick = () => {
    if (pointsToSpend >= availableCredits) {
      setError("You can't redeem more points than you have");
    } else if (pointsToSpend <= 0) {
      setError('You need to enter a number of points to redeem');
    } else if (pointsToSpend > orderTotal) {
      setError("You can't spend more points than your order total");
    } else {
      setError('');
      dispatch(setLoyaltySpend(pointsToSpend * 100)); // This is hardcoded 100 on purpose, 10 ** currencyDecimals is off by 10x for 3dp currencies, I don't know why
      onChange(pointsToSpend);
      setApplied(true);
    }
  };

  return (
    <>
      <Title>Redeem your Vapiano Lovers Gift Credits</Title>
      <FormattedMessage
        defaultMessage="{credits} Credits available"
        values={{ credits: <FormattedPrice value={availableCredits} /> }}
      />

      <CouponContainer>
        <InputContainer>
          <Label htmlFor="como-spend" asPlaceholder={pointsToSpend == null} required={false}>
            Redeem Credits
          </Label>

          <StyledInput
            name="como-spend"
            type="number"
            max={availableCredits}
            min={0}
            disabled={applied}
            value={pointsToSpend || ''}
            onChange={e => {
              const points = parseFloat(e.target.value);
              setPointsToSpend(Number.isNaN(points) ? null : points);
            }}
          />

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </InputContainer>

        <BtnContainer>
          <ActionButton
            onClick={handleClick}
            buttonType="button"
            disabled={!pointsToSpend}
            label={applied ? 'Applied' : <FormattedMessage defaultMessage="Apply" />}
          />
        </BtnContainer>
      </CouponContainer>
    </>
  );
};

export default Como;
