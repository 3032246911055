import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Map, List } from 'immutable';

import { usePrevious } from 'hooks';
import { isPreOrderDatesOnly } from 'selectors/features';
import {
  isLoadingFulfilmentMethodTimeSlots,
  selectTimeSlot,
  selectFulfilmentId,
  selectFulfilmentMethodTimeSlots,
  selectVenueTimezone,
} from 'selectors/root';
import { getFulfilmentMethodTimeSlots } from 'actions/venue';
import { getRelativeDate, convertDateToVenueTimezone } from 'utils/date';
import { noTimeslotsError } from 'components/CheckoutForm/checkoutErrorsUtil';
import globalMessages from 'components/globalMessages';
import Dropdown from 'components/Dropdown';
import messages from './messages';

export const generateDaysOptions = (options, venueDate) =>
  options.map(option => ({
    label: getRelativeDate(option, venueDate),
    value: option,
  }));

export const generateTimeslotOptions = (options, formatMessage) => {
  const formatUnavailableMessage = time => formatMessage(globalMessages.optionUnavailable, { label: time });
  return options.map(option => ({
    label: option.get('available') ? option.get('start') : formatUnavailableMessage(option.get('start')),
    value: option.get('start'),
    disabled: !option.get('available'),
  }));
};

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool,
  haserror: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  timeslots: PropTypes.instanceOf(Map),
  defaultTimeslot: PropTypes.object,
  intlIdentifier: PropTypes.string,
  venueDate: PropTypes.instanceOf(Date),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  fulfilmentId: PropTypes.number,
  isLoading: PropTypes.bool,
  noTimeslotsError: PropTypes.bool,
  getFulfilmentMethodTimeSlots: PropTypes.func.isRequired,
  preOrderDatesOnly: PropTypes.bool,
};

const defaultProps = {
  intlIdentifier: 'delivery',
  timeslots: new Map(),
};

const FulfilmentDateTime = ({
  value,
  required,
  haserror,
  onChange,
  onBlur,
  timeslots,
  defaultTimeslot,
  intlIdentifier,
  intl,
  venueDate,
  isLoading,
  noTimeslotsError,
  fulfilmentId,
  getFulfilmentMethodTimeSlots,
  preOrderDatesOnly,
}) => {
  const [prevIsLoading] = usePrevious(isLoading);

  useEffect(() => {
    if (!!prevIsLoading && !isLoading && !value && defaultTimeslot) {
      onChange(defaultTimeslot);
      onBlur(defaultTimeslot);
    }
  }, [prevIsLoading, isLoading, value, defaultTimeslot, onChange, onBlur]);

  useEffect(() => {
    getFulfilmentMethodTimeSlots(fulfilmentId);
  }, [fulfilmentId, getFulfilmentMethodTimeSlots]);

  const dateTimeslots = value.date && timeslots.get(value.date) ? timeslots.get(value.date) : new List();
  const [...dateOptions] = timeslots.keys();

  const dropdownOptions = {
    isLoading,
    required,
    haserror,
    disabled: noTimeslotsError,
    placeholder: noTimeslotsError && <FormattedMessage {...messages.noTimesAvailable} />,
  };

  return (
    <>
      <Dropdown
        {...dropdownOptions}
        value={value.date}
        onChange={val => {
          const dateTime = {
            date: val,
            time:
              timeslots
                .get(val)
                .find(timeslot => !!timeslot.get('available'))
                .get('start', '') || '',
          };
          onChange(dateTime);
          onBlur(dateTime);
        }}
        options={generateDaysOptions(dateOptions, venueDate)}
        label={<FormattedMessage {...messages[`${intlIdentifier}Date`]} />}
      />

      {!preOrderDatesOnly && (
        <Dropdown
          {...dropdownOptions}
          value={value.time}
          onChange={val => {
            const dateTime = { ...value, time: val };
            onChange(dateTime);
            onBlur(dateTime);
          }}
          options={generateTimeslotOptions(dateTimeslots, intl.formatMessage)}
          label={<FormattedMessage {...messages[`${intlIdentifier}Time`]} />}
        />
      )}
    </>
  );
};

FulfilmentDateTime.defaultProps = defaultProps;
FulfilmentDateTime.propTypes = propTypes;

export default connect(
  state => ({
    fulfilmentId: selectFulfilmentId(state),
    isLoading: isLoadingFulfilmentMethodTimeSlots(state),
    timeslots: selectFulfilmentMethodTimeSlots(state),
    defaultTimeslot: selectTimeSlot(state),
    noTimeslotsError: noTimeslotsError(state),
    venueDate: convertDateToVenueTimezone(new Date(), selectVenueTimezone(state)),
    preOrderDatesOnly: isPreOrderDatesOnly(state),
  }),
  { getFulfilmentMethodTimeSlots }
)(injectIntl(FulfilmentDateTime));
