const getLabel = (label, intl) => {
  if (label[intl]) label = label[intl];
  else label = label[Object.keys(label)[0]];
  return label;
};

export default (fields, locale, methodId) => {
  if (!fields) return;
  fields = fields.toJS();
  return fields.map(field => {
    const label = getLabel(field.label, locale);
    delete field.label;
    let sortOrder = 9999;
    if (field && field.sort_orders && field.sort_orders[methodId]) {
      sortOrder = field.sort_orders[methodId];
      delete field.sort_orders;
    }
    switch (field.type) {
      case 'text':
      case 'integer':
        return {
          ...field,
          errorKey: `additional_fields.${field.name}`,
          type: field.type === 'integer' ? 'number' : field.type,
          name: field.name,
          intlLabel: label,
          sortOrder,
          storeDetail: true,
        };
      case 'message':
        return {
          ...field,
          type: 'component',
          component: 'Message',
          name: field.name,
          intlLabel: label,
          sortOrder,
        };
      case 'choice':
        if (field.expanded === false) {
          return {
            ...field,
            errorKey: `additional_fields.${field.name}`,
            type: 'component',
            component: 'DropdownField',
            name: field.name,
            options: field.choices,
            intlLabel: label,
            sortOrder,
            storeDetail: true,
          };
        }
        return {
          ...field,
          errorKey: `additional_fields.${field.name}`,
          type: 'component',
          component: 'CustomFieldsMultiChoice',
          name: field.name,
          options: field.choices.map(choice => ({ id: choice, value: choice, name: choice })),
          intlLabel: label,
          sortOrder,
          storeDetail: true,
        };
      default:
        return null;
    }
  });
};
