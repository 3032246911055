import { setItem, removeItem, removeExpiredKeys } from 'utils/localStorage';
import { SET_DETAILS, REMOVE_DETAIL } from 'actions/storage/constants';
import { removeDetail, clearDetails } from 'actions/storage';
import { kioskModeEnabled } from 'selectors/features';
import { openInactiveAlert } from 'actions/UI';
import { selectAlertStack, selectKioskTimeout } from 'selectors/ui';
import { CLEAR_ORDER, SET_COMPLETED_ORDER } from 'actions/order/constants';
import { ORDER_STATE_CONFIRMED } from 'appConstants';

setInterval(removeExpiredKeys, 600000); // remove expired keys every 10 minutes

let inactiveTimeout;

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const result = next(action);
    const state = getState();
    const kioskMode = kioskModeEnabled(state);
    const KIOSK_TIMEOUT = selectKioskTimeout(state);

    const removeCustomFields = state => {
      const additionalFields = state.getIn(['venue', 'additional_fields']);

      if (additionalFields) {
        additionalFields
          .filter(field => state.hasIn(['storage', 'data', field.get('name')]))
          .forEach(field => dispatch(removeDetail(field.get('name'))));
      }
    };

    if (kioskMode) {
      const currentAlertComponent = selectAlertStack(state).last()?.get('component');
      if (inactiveTimeout) clearTimeout(inactiveTimeout);
      if (
        currentAlertComponent !== 'INACTIVE_ALERT' &&
        currentAlertComponent !== 'IFRAME_ALERT' && // don't open inactive alert if payment gateway is open
        typeof window === 'object' &&
        window?.location?.pathname !== '/processing' &&
        window?.location?.pathname !== '/'
      ) {
        inactiveTimeout = setTimeout(() => {
          dispatch(openInactiveAlert());
          clearTimeout(inactiveTimeout);
        }, KIOSK_TIMEOUT);
      }
    }

    if (action.type === SET_DETAILS) {
      if (kioskMode) action.expiry = { format: 'minutes', length: 5 };
      Object.keys(action.data).forEach(key => {
        setItem(`storage.${key}`, action.data[key], action.expiry);
      });
    }
    if (action.type === REMOVE_DETAIL) {
      removeItem(`storage.${action.key}`);
    }

    if (
      action.type === SET_COMPLETED_ORDER ||
      action.type === CLEAR_ORDER ||
      (action.type === '@@CLIENT_INIT' &&
        state.getIn(['global', 'completedOrder', 'state']) === ORDER_STATE_CONFIRMED)
    ) {
      dispatch(removeDetail('fulfilmentDateTime'));
      dispatch(removeDetail('promotion_coupon'));
      dispatch(removeDetail('notes'));
      dispatch(removeDetail('payment'));
      removeCustomFields(state);
      if (kioskMode) {
        dispatch(clearDetails());
      }
    }

    return result;
  };
